$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.jixiang-subject-wrapper{
  background: #846344;
  position: relative;
  .img{
    display: block;
    width: 7.5rem;
    position: relative;
    z-index: 1;
    margin:0 auto;
  }
  .img1{
    height: 7.24rem;
  }
  .img2{
    //height: 4.54rem
    height: 7.3rem;
  }
  .img3{
    height: 5.1rem;
  }
  .img4{
    height: 13.6rem;
  }
  .box{
    background: #8a694a;
    width: 100%;
    padding:.3rem 0 .6rem;
    z-index: 100;
    .button-box{
      width: 4.3rem;
      height: .77rem;
      margin: 0 auto;
      border:0;
    }
    .erweima-box{
      width: 2.8rem;
      height: 2.94rem;
      margin: 0 auto;
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}