$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.my-center-wrapper{
  .member-wrapper{
    position: relative;
    padding:.4rem .3rem;
    background: $white-color;
    margin-bottom: .3rem;
    .member-box{
      width: 6.9rem;
      padding:.3rem .2rem .5rem;
      border-radius: 5px;
      box-sizing: border-box;
      .user-info{
        width:4rem;
        .name {
          font-size: 16px;
          margin-right: 5px;
        }
        .card-name {
          border-style: solid;
          border-width: 1px;
          font-size: 12px;
          padding: 0 5px;
          height: 18px;
          line-height: 18px;
        }
      }
      .exit{
        height: 26px;
        line-height:26px;
        width:80px;
        text-align: center;
        border-radius: 10px;
        font-size: 12px;
        margin-top: 3px;
        background: $white-color;
        color: $brown-color;
      }
    }
    .phone{
      font-size: 13px;
    }
    .certification{
      background: $primary-v2-color;
      color: $white-color;
      padding: 3px 12px;
      border-radius: 15px;
      font-size: 12px;
      text-align: center;
    }
    .level1{
      background:#F3D0C4;
      color: #c27c5d;
      .card-name{
        border-color: #c27c5d;
      }
    }
    .level2{
      background: #e0e0e0;
      color:#666;
      .card-name{
        border-color: #666;
      }
    }
    .level3{
      background: #e0d2ab;
      color:#887131;
      .card-name{
        border-color: #887131;
      }
    }
    .level4{
      background: #666666;
      color:#dcc3a5;
      .card-name{
        border-color: #dcc3a5;
      }
    }
  }
  .login-box{
    padding:.6rem .3rem;
    background:#fff;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    margin-bottom: .3rem;
  }
  .nav-link{
    padding: 0 .3rem;
    background: $white-color;
    .nav{
      color:$desc-color;
      padding:.3rem 0;
      border-top: 1px solid $border-color;
      &:first-child{
        border-top:none
      }
    }
  }
}