$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.order-list-wrapper{
  .search-box{
    border-top: 1px solid $border-color;
    height: 46px;
    line-height: 46px;
    padding: 0 .3rem;
    background: $white-color;
  }
  .order-scroll-wrapper{
    position: fixed;
    top: 0px;
    bottom: 0;
    width: 100%;
  }
  .list{
    padding:.3rem;
    .order{
      margin-bottom:.3rem;
      box-shadow: 0 0 5px #ddd;
      .content{
        background: $white-color;
        padding:.2rem;
        border-radius: 5px;
        .no{
          font-size: 12px;
          color:$desc-v3-color;
        }
        .space{
          padding-bottom: .2rem;
          border-bottom: 1px dotted $border-color;
          margin-bottom: .2rem;
        }
        .title{
          .name{
            font-weight: bold;
          }
          text{
            display: inline-block;
            margin-right: 5px;
          }
        }
        .unpaidPrice{
          text-align: right;
          padding-top:2px;
          color:#ebae03;
          font-size: 12px;
        }
        .type{
          font-size: 11px;
          border-radius: 10px;
          padding:2px 5px;
          color:#fff;
          margin-right: .1rem;
        }
        .booking-date{
          font-size: 12px;
          color:#999;
        }
        .check-date{
          font-size: 14px;
          color:#999;
        }
      }
      .price-box{
        padding-top:.2rem;
      }
      .price-default{
        font-size: 12px;
        color:#333;
      }
      .price{
        font-size: 12px;
        color:#fb5f0a;
        padding-left: .3rem;
        .num{
          font-size: 16px;
        }
      }
      .price-default{
        .num{
          font-size: 16px;
        }
      }
      .quan{
        font-size: 11px;
        margin-right: .1rem;
        padding: 0 .06rem;
        background: #fc8f53;
        color: $white-color;
      }
      .operate{
        border-top: 1px dotted $border-color;
        margin-top: .2rem;
        padding-top: .2rem;
        position: relative;
        .btn{
          padding:.1rem .2rem;
          border:1px solid #ddd;
          border-radius: 5px;
          margin-left: .3rem;
          font-size: 14px;
        }
      }
      .more{
        color: $desc-v3-color;
        padding:.3rem;
        text-align: center;
        font-size:13px;
      }
      .endBox{
        width: 100%;
        background: $bg-color;
        position:relative;
        color:$desc-light;
        padding:.3rem .3rem .3rem;
        text-align: center;
      }
      .endLine{
        position: relative;
        display: inline-block;
        z-index: 1;
        width: 250px;
        height: 1px;
        background: #ddd;
      }
      .endTxt{
        position: relative;
        display: inline-block;
        background: $bg-color;
        height: 14px;
        padding: 0 10px;
        z-index: 2;
        top: -14px;
        font-size:13px;
      }
    }
    .GDS_HOTEL{
      .type{
        background: #f19149;
      }
    }
    .HOTEL_PACKAGE{
      opacity: .5;
      .type{
        background: #13b5b1;
      }
    }
  }
}

.status{
  font-size: 14px;
  font-weight: bold;
}
.status-PENDING, .status-CANCEL_PENDING, .status-UN_APPOINTMENT{
  color: #ebae03;
}
/*成功*/
.status-SUCCESS, .status-APPOINTMENT, .status-PART_APPOINTMENT{
  color: #09BB07;
}
/*取消*/
.status-CLOSE, .status-CANCEL, .status-CANCEL{
  color: #888;
}
/*失败*/
.status-FAILURE{
  color: #E64340;
}