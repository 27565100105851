$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.hotel-base-wrapper{
  .module{
    margin-bottom: .3rem;
    padding: .3rem;
    background: #fff;
    box-sizing: border-box;
    .title {
      padding-bottom: .3rem;
      font-weight: bold;
      border-bottom: 1px solid #e5e5e5;
    }
    .name {
      padding-bottom: .3rem;
      .cn {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10rpx;
      }
      .en {
        color: #999;
      }
    }
    .base{
      .view {
        padding-bottom: .3rem;
        padding-top: .3rem;
        border-bottom: 1px solid #e5e5e5;
      }
      .addr-box{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    .content {
      background: #fff;
      color: #333;
      position: relative;
      .tag {
        flex-shrink: 0;
        width: 1.2rem;
        color: #333;
      }
    }
    .fami-content {
      padding-top: .2rem;
    }
    .base {
      border-top: 1px solid #e5e5e5;
    }
  }
}