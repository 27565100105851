$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.hotel-list-wrapper{
  .search-module{
    position:fixed;
    top:0;
    left:0;
    height:60px;
    padding:10px .15rem 10px;
    background:$white-color;
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    .search-box{
      display:flex;
      flex-direction:row;
      flex-wrap: nowrap;
      height: 40px;
      line-height:40px;
      font-size: 13px;
      border-radius: 5px;
      background:$bg-color;
      .overflow-txt{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .area{
        color:#333;
        width: 2rem;
        text-align: center;
        border-right: 1px solid #e5e5e5;
        margin-right: .1rem;
      }
      .date-box{
        height:30px;
        margin-top:5px;
        .date{
          font-size: 12px;
          height: 15px;
          line-height: 15px;
          display:flex;
          flex-direction: row;
          .desc-light{
            margin-right: 2px;
          }
        }
      }
      .keyword{
        color:$desc-v3-color;
        flex:1;
        padding:0 10px;
      }
    }
  }
  .no-data{
    padding:.3rem 0;
    text-align:center;
    color:$desc-v3-color;
  }
  .hotel-scroll-wrapper{
    transform: translateX(-50%);
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 100%;
    .hotel-list{
      background:$bg-color;
      padding-bottom: .3rem;
      .item{
        display:flex;
        flex-direction:row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        overflow: hidden;
        padding:.2rem;
        box-sizing: border-box;
        margin-top:5px;
        background:$white-color;
        .img-box{
          height: 2rem;
          width: 2rem;
          background: #eee;
          flex-shrink: 0;
          .main-img{
            width:100%;
            height:100%;
            border-radius: 5px 0;
          }
        }
        .content{
          padding-left: .2rem;
          min-height: 2rem;
          overflow: hidden;
          flex:1;
          flex-shrink: 1;
          color:#333;
          box-sizing: border-box;
          position: relative;
          .name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .nameCn{
            font-size: 18px;
            font-weight: bold;
          }
          .nameEn{
            font-size: 12px;
            padding-bottom: 4px;
            color: #b2b2b2;
          }
          .addr{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #999;
            font-size: 12px;
            padding-bottom: 4px;
            padding-top: 4px;
            width:4.9rem;
            .icon-dizhi{
              font-size:13px;
              margin-right: 2px;
            }
          }
          .member{
            position: relative;
            .member-image {
              width: .4rem;
              height: .3rem;
              margin-right: 4px;
            }
            .member-name {
              position: relative;
              padding: 1px 3px;
              font-size: 13px;
              color: #928464;
              z-index: 3;
              .name {
                max-width: 2.1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                box-sizing: border-box;
              }
              .bar {
                z-index: 1;
                background: #f5e8d2;
                height: 3px;
                margin-top: -6px;
              }
            }
          }
          .global-price{
            font-size: 12px;
            color: $primary-color;
            .num {
              font-size: 18px;
              padding-right: 2px;
            }
          }
          .no-price{
            position: absolute;
            bottom: .6rem;
            right: 0.3rem;
            img{
              width: 1rem;
              height: 1rem;
              opacity: 0.5;
            }
          }
          .benefitsRate{
            background: $primary-color;
            height: 17px;
            line-height: 15px;
            padding-left:1px;
            text-align: center;
            border: 1px solid $primary-color;
            border-radius: 3px;
            display: inline-block;
            margin-right: 2px;
            .icon-huangguan {
              color: $white-color;
              font-size: 14px;
              margin-right: 2px;
            }
          }
          .loading-price{
            width:.4rem;
            height:.4rem;
            margin-right: 2px;
          }
        }
      }
      .loading-box{
        background:#f4f4f4;
        padding:.15rem;
        text-align:center;
        box-sizing: border-box;
      }
    }
  }
}

