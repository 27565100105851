$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.hotel-detail-wrapper{
  position: relative;
  .photos-wrapper{
    width:100%;
    height:3.28rem;
    img{
      width:100%;
      height:100%;
    }
  }
  .base-info-container{
    position: relative;
    padding:.2rem .3rem;
    background: $white-color;
    .arrow{
      color:$link-color;
    }
    .name{
      font-weight: 600;
      font-size: 18px;
      color:$desc-color;
    }
    .name-en{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 11px;
      color:$desc-v2-color;
    }
    .address{
      display: flex;
      flex-direction: row;
      color:$desc-v2-color;
      font-size:12px;
      align-items:center;
      .icon-dizhi{
        font-size: 15px;
        margin-right: 0;
      }
      .text{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .booking-container{
    position: relative;
    padding-bottom: 50px;
    .condition-box{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      padding: 15px;
      background: #fff;
      border-top: 1px solid #dedad0;
      border-bottom: 1px solid $border-color;
      overflow: hidden;
      .static-date-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        width: 5.2rem;
        padding-right: .3rem;
        .dateNum {
          font-size: 16px;
          align-items: flex-end;
          font-weight: 600;
        }
        .dateDay {
          font-size: 12px;
          margin-left: 3px;
        }
      }
      .line {
        width: 15px;
        height: 1px;
        background: $border-color;
        margin: 0 10px;
      }
      .tax-tip {
        font-size: 11px;
        text-align: center;
        background: $success-color;
        color: $white-color;
        padding: 1px 2px;
        width: 66px;
        border-radius: 3px;
      }
    }
    .loading-box{
      text-align: center;
      background: $white-color;
      padding: .6rem;
      justify-content: center;
      height: 1.8rem;
      color:#999;
      .icon{
        width:.4rem;
        height:.4rem;
        margin-right: 2px;
      }
      .loading-txt{
        color: $desc-v3-color;
      }
    }
    .room-list{
      background:$bg-color;
      padding-bottom: .3rem;
      .room-box{
        position: relative;
        background: #fff;
        margin-bottom: .2rem;
        .room-info{
          min-height: 1.2rem;
          .orderNum {
            position: absolute;
            left: 2px;
            top: 1px;
            color: #fff;
            z-index: 11;
            font-size: 11px;
          }
          .orderNumBg {
            position: absolute;
            left: 0;
            top: 0;
            border-width: 13px;
            border-style: solid;
            border-color: #dedad0 transparent transparent #dedad0;
            z-index: 10;
          }
          .room-type{
            box-sizing: border-box;
            width: 5.1rem;
            padding: .2rem;
            font-size: 16px;
            word-break: break-all;
            min-height: 1.2rem;
            flex-shrink: 0;
            
            .parseRoom {
              font-size: 16px;
              padding-left: 5px;
            }
            .room-img{
              width: 1.5rem;
              height: 1.5rem;
              flex-shrink: 0;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .mactch-room {
              margin-left: .2rem;
              flex:1;
              div {
                width: 3.8rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .hotel-attr {
                font-size: 11px;
                color: #999;
                span{
                  margin-left:5px;
                  &:first-child{
                    margin-left: 0;
                  }
                }
              }
              .room-extra{
                font-size: 12px;
                color: #999;
                padding-left:10rpx;
              }
            }
          }
          .price-wrapper{
            flex: 1;
            padding-right: .2rem;
            box-sizing: border-box;
            .base-line{
              box-sizing: border-box;
              align-items: baseline;
              justify-content: flex-end;
            }
            .global-price {
              font-size: 12px;
              color: $primary-color;
              .num {
                font-size: 18px;
                padding-right: 2px;
              }
              .base {
                color: $desc-v3-color;
            }
            }
            .rateCodePrice {
              text-align: right;
              padding-right: 10px;
              box-sizing: border-box;
              .neihan{
                color: #fba30a;
                font-size: 11px;
                padding-right: 2px;
              }
            }
          }
        }
        .room-info-active{
          background: #EDEBD6;
        }
        .rate-list{
          background:$white-color;
          .rate-plan{
            position: relative;
            border-top: 1px dashed $border-color;
            &:first-child {
              border-top:none
            }
            .rate-item {
              min-height:1.2rem;
              .roomPrice {
                display: flex;
                padding: .2rem .2rem .2rem .2rem;
                width: 5.6rem;
                .plan {
                  flex:1;
                  padding-right: .1rem;
                  vertical-align: middle;
                  box-sizing: border-box;
                  .bedCn{
                    margin-left: 5px;
                  }
                  .txtDanger {
                    color: $danger-color!important;
                  }
                  .planTxt {
                    position: relative;
                    width: 3.7rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                    font-size: 12px;
                    color: $desc-v3-color;
                  }
                  .cancelInfoShortTran {
                    font-size: 12px;
                  }
                }
                .planPrice {
                  flex-shrink: 0;
                  width: 1.8rem;
                  vertical-align: middle;
                  text-align: center;
                  box-sizing: border-box;
                  .benefitsRate {
                    color: #fff;
                    background: $primary-color;
                    height: 18px;
                    line-height: 18px;
                    border: 1rpx solid $primary-color;
                    z-index: 2;
                    border-radius: 3px;
                    justify-content: center;
                    width: 1.40rem;
                    margin-left: .2rem;
                    .icon-huangguan {
                      color: $white-color;
                      margin-right: 0;
                      font-size: 14px;
                   }
                   .benefitsRate .zx-txt {
                      padding-left: 0;
                      font-size: 10px;
                      color: $white-color;
                      padding-right: 2px;
                    }
                  }
                }
                .global-price {
                  font-size: 12px;
                  color: $primary-color;
                  .num {
                    font-size: 18px;
                    padding-right: 2px;
                  }
                }
              }
              .disable-booking{
                text-align: center;
                width: 1.26rem;
                vertical-align: middle;
                .highlight{
                  font-size:13px;
                  letter-spacing:0;
                }
                .kefu-txt{
                  font-size:12px;
                  transform: scale(.9);
                  color:$desc-v2-color;
                  letter-spacing:0;
                }
              }
              .booking-box {
                width: 1.3rem;
                box-sizing: border-box;
                flex-shrink: 0;
                padding-right: .2rem;
                .bookingBtn {
                  text-align: center;
                  width: 1.1rem;
                  height: 46px;
                  border-radius: 5px;
                  background-image: linear-gradient(to right, $primary-v3-color, $primary-color);
                  padding:1px;
                  box-sizing: border-box;
                  span{
                    flex:1;
                  }
                  .bookingTxt {
                    height: 24px;
                    font-size: 13px;
                    line-height: 24px;
                    color: $white-color;
                    border-radius: 5px 5px 0 0;
                  }
                  .guaranteeTypeTxt {
                    font-size: 11px;
                    background:$white-color;
                    border-radius: 0 0  5px 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rate-detail-wrapper {
  position: relative;
  height: 9.1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  width: 7.5rem;
  padding:0 0 5px;
  .zhuanxiang-card {
    position: relative;
    width: 7.5rem;
    height: .96rem;
    margin: 0 auto;
    z-index: 2;
    background: url(https://staticfile.badazhou.com/20201106/0d19bf3cb5e3cf0b01a71e70fac8342c.png) #f4f4f4;
    background-size: 7.5rem 1.26rem;
    background-position: 0;
    border-radius: 5px 5px 0 0;
    .title {
      height: 32px;
      color: #734802;
      padding-left: .2rem;
      font-size: 14px;
      font-weight: 450;
      .icon-huangguan {
        color: #734802;
        font-size: 21px;
      }
    }
  }
  .huiyuan-card {
    position: relative;
    color: #734802;
    width: 7.5rem;
    height: .96rem;
    margin: 0 auto;
    z-index: 2;
    background: url(https://staticfile.badazhou.com/20210127/a68e101f0dcafc60217d7b56ab83017f.jpg) #f4f4f4;
    background-size: 7.5rem 1.26rem;
    background-position: 0;
    border-radius: 5px 5px 0 0;
    .title {
      padding-top:2px;
      height: 26px;
      padding-left: .2rem;
      font-size: 14px;
      font-weight: 450;
    }
    .huiyuan-tip {
      padding-left: .2rem;
      margin-top: -4px;
      font-size: 11px;
    }
  }
  .room-detail-content {
    padding-bottom: .6rem;
    .room-content{
      .room-name{
        padding:0 .3rem;
      }
      .room-info {
        box-sizing: border-box;
        padding:0 .3rem;
        font-size: 14px;
        .room-attr-box {
          flex-wrap: wrap;
          padding-top: .1rem;
        }
        .room-attr {
          width: 3.45rem;
          margin-top: .1rem;
          box-sizing: border-box;
          padding-right: .1rem;
          .room-info-tag {
            color: $desc-v3-color;
            width: .72rem;
            flex: none;
          }
        }
      }
      .room-name {
        color: $desc-color;

      }
    }
    .item-box{
      .item-box-title {
        padding-bottom: .1rem;
        color: $desc-color;
      }
      .item-box-content {
        position: relative;
        color: $desc-v2-color;
      }
    }
    .cancel-box {
      margin-top: .2rem;
      margin-bottom: .2rem;
      .cancel-en{
        .text-link{
          flex-shrink: 0;
        }
      }
    }
    .margin-top-20 {
      margin-top: .2rem;
    }
  }
  .bar {
    height: 5px;
    background: $bg-color;
  }
  .rate-detail-content{
    padding-top: .2rem;
    width: 6.90rem;
    margin: 0 auto;
    font-size: 14px;
    .loading{
      .icon{
        width:.4rem;
        height:.4rem;
        margin-right: 2px;
      }
    }
    .benefitsRate {
      color: $white-color;
      background: $primary-color;
      height: 18px;
      line-height: 18px;
      border: 1px solid $primary-color;
      z-index: 2;
      border-radius: 3px;
      justify-content: center;
      width: 1.4rem;
      margin-right:.2rem;
      .icon-huangguan {
        color: #fff;
        margin-right: 0;
        font-size: 14px;
        .zx-txt {
          padding-left: 0;
          font-size: 10px;
          color: #fff;
          padding-right: 2px;
        }
      }
    }
    .benefit-detail-box {
      position: relative;
      background: #fff;
      padding: .2rem 0;
      font-size: 14px;
      .benefit-detail {
        padding: 5px;
        background: #F6F3EB;
        border: 1px solid #c7b891;
        .benefit-info {
          padding: 8px;
          color: #986326;
          border-radius: 2px;
          background: #fff;
          border: 1px dotted #d1c6aa;
          .benefit-item {
            padding-top: .2rem;
            &:first-child{
              padding-top:0;
            }
            .symbol {
              width: 6px;
              height: 6px;
              margin-top: 6px;
              background-image: linear-gradient(to right, #e1b37e , #fbd8ac);
              border-radius: 1px;
              margin-right: .2rem;
              transform: rotate(45deg);
              flex: none;
            }
          }
        }
      }
    }
  }
}

.room-rateplan-booking{
  width: 7.50rem;
  height: 1rem;
  box-sizing: border-box;
  box-shadow: 1px -1px 4px #ccc;
  background: #fff;
  .totalPriceBox{
    padding:0 .3rem;
  }
  .room-name{
    padding-bottom: .1rem;
    color:$desc-color;
  }
  .totalPrice{
    font-size: 18px;
    color: $primary-color;
    align-items: baseline;
    .originCurrency{
      font-size: 14px;
    }
  }
  .totalTxt{
    color: $desc-light;
    font-size: 11px;
  }
  .booking{
    background: $primary-v2-color;
    color:#fff;
    height:1rem;
    line-height: 1rem;
    width: 2.6rem;
    text-align: center;
  }
  .kefu-box{
    padding:0 .3rem;
    .icon-icon052{
      margin-right: 0;
      font-size: 20px;
      margin-top:3px;
      text-align: center;
    }
    .fs-11{
      margin-top:-4px;
    }
  }
}