$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.goto-wxapp-wrapper{
  box-sizing: border-box;
  background: $white-color;
  position: relative;
  z-index: 1;
  .img-wrapper{
    width:7.5rem;
    height: auto;
    margin: 0 auto;
    .img{
      display: block;
      width: 100%;
    }
  }
  .go-to-title{
    padding:.4rem 0 .2rem;
    text-align:center;
    font-size: 16px;
  }
  .skip-info{
    color:$desc-v3-color;
    text-align:center;
  }
  .icon-sousuo{
    font-size:16px
  }
  .button-wrapper{
    position:fixed;
    bottom: 0;
    left:0;
    width: 100%;
    height: 1.4rem;
    padding:.3rem;
    z-index: 1000;
    box-shadow: 0px 0px 7px #ccc;
    background: #fff;
  }
}
.h5{
  padding-bottom: 1.2rem;
}
.weixin{
  .img-wrapper{
    max-height: 8.9rem;
    overflow-y: scroll;
    margin-bottom: .3rem;
  }
}