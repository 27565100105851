$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.about-wrapper{
  padding:.3rem;
  background: $white-color;
  box-sizing: border-box;
  .content{
    position: relative;
    img{
      display: block;
      width: 6.9rem;
      margin-top: 1rem;
      &:first-child{
        margin-top: 0;
      }
    }
    .img1{
      height: 5rem;
    }
    .img2{
      height: 8.72rem;
    }
    .img3{
      height: 8.46rem;
    }
    .btn{
      justify-content: center;
      margin-top: .3rem;
      margin-bottom: .3rem;
      .icon-shouye{
        color:#fff;
      }
    }
  }
}
