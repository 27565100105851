$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.search-wrapper{
  background: #fff;
}
.searchBarBox{
  padding:.3rem .3rem 0;
}
.item-tilte{
  width: 6.9rem;
  margin:0 auto;
  font-size: 15px;
  font-weight: bold;
}
.brand-box .marginNone, .search-module .marginNone{
  margin-right: 0!important;
}
.recommed-module{
  position: relative;
  z-index: 1;
}

/* 限时特惠酒店 */
.search-module .hotel-list{
  padding: .16rem .3rem 0 .3rem;
  flex-wrap: wrap;
}
.search-module .hotel-list .item-tilte{
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: .2rem;
  padding-top:.14rem;
}
.search-module .hotel-list .li{
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  padding-top:.14rem;
  padding-bottom: .14rem;
}
.search-module .hotel-list .li:last-child{
  border-bottom: none;
}
.search-module .hotel-list .li .hotel-info{
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right:.2rem;
  flex:1;
  box-sizing: border-box;
}
.hotel-info .hot-rank{
  font-size:11px;
  color:#999;
}
.search-module .hotel-list .cuxiao{
  color: #999;
  font-size: 11px;
}
.search-module .hotel-list .main-img{
  width: .34rem;
  height: .46rem;
  flex: none;
  margin-right:.1rem;
}
.search-module .hotel-list .main-img image{
  width: 100%;
  height: 100%;
}
.search-module .price-box{
  width: 1.3rem;
  flex: none;
  text-align: right;
  font-size: 14px;
  color: #fb5f0a;
}
.search-module .price-box text{
  color:#b2b2b2;
  font-size: 10px;
}
.search-module .price-box .price-num{
  font-size: 14px;
  color: #fb5f0a;
}
/*搜索框返回结果样式*/
.keywordRelated{
  position: relative;
  background: #ffffff;
  padding-left:.3rem;
  padding-right: .3rem;
  z-index: 100;
}
.keywordRelated .result{
  display: block;
  font-size: 14px;
  padding-top: .3rem;
  padding-bottom: .3rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.keywordRelated .result .iconfont{
  font-size: 16px;
}
/* 搜索没有结果 */
 .search-none{
  padding:.3rem;
}
 .search-none .search-icon{
  display: block;
  width: 1.6rem;
  height: 1.16rem;
  margin: 1rem auto 0;
}
.search-none .search-txt{
  font-size: 14px;
  padding-top: .3rem;
  text-align: center;
  color: #999;
}
/* 品牌展示样式 */
.brand-box{
  padding-top: .3rem;
  width: 100%;
  padding-bottom: .3rem;
}
.brand-box .brand-list{
  width: 100%;
  padding:.2rem .3rem 0;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.brand-box .brand-detail{
  flex: 1;
  justify-content: center;
}
.brand-box .brand{
  width:2.1rem;
  position: relative;
  box-sizing: border-box;
  background: #f4f4f4;
  margin-right: .2rem;
  margin-bottom: .2rem;
  padding:.12rem .2rem;
  text-align: center;
  border-radius: 5px;
}
.brand-box .brand.active{
  background: #fc8f53;
  color:#fff;
}
.margin-none{
  margin-right: 0!important;
}
.brand-box .name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.brand-box .nameEn{
  font-size: 11px;
  color: #999;
}
.brand-box .nameCn{
  font-size: 15px;
}
.brand-box .brand-info{
  height: .26rem;
  line-height:.26rem;
  font-size: 10px;
  color: #999;
}
.brand-box .brand .icon-huangguan{
  color: #fc8f53;
  font-size: 18px;
  margin-right: 3px;
}
.brand-box .checked{
  background: #fc8f53;
}
.brand-box .checked .name{
  color: #fff;
}
.brand-box .checked .icon-huangguan{
  color: #fff;
}
.brand-box .checked text{
  color: #fff;
}
.brand-box .checked  .has-benefits{
  border-color: #fff;
}
.brand-box .brandBtnWrap{
  padding: 10px .3rem 0;
  height: 70px;
  background: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.brand-box button{
  width: 6.9rem;
  height:45px;
  line-height:45px;
  border:0;
}
.brand-box .privilegeInfo{
  color: #3498DB;
  border-bottom: 1px solid #3498DB;
  padding-bottom: 3px;
}
.brand-box .youdai{
  padding-top: .2rem;
  padding-left: .3rem;
}
.brand-box .youdai image{
  display: block;
  width: 6.3rem;
  height: .28rem;
}
.show-all-list{
  font-size: 12px;
  font-weight: 400;
}
.tip {
  color: #b2b2b2;
  font-size: 12px;
}