/* reset css */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
body {
	font-family: PingFangSC-Regular,Microsoft YaHei,Helvetica,Arial,sans-serif;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-text-size-adjust: none;
  background: #ffffff;
  font-size: 15px;
}
a,a:active,a:focus,a:hover,a:visited {
	text-decoration: none
}
a,img {
	-webkit-touch-callout: none
}
li,ol,ul {
	list-style: none
}

input[type=password],input[type=text],textarea {
	resize: none;
	outline: 0;
	-webkit-appearance: none;
	white-space: pre-wrap;
	word-wrap: break-word;
	background: none;
  outline: none;
  border: none;
  -webkit-appearance:none;
}
input:focus {
  border: none;
}