$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.login-wrapper{
  padding:$space-number;
  box-sizing: border-box;
  background:$white-color;
  height:100vh;
  .logo{
    display: block;
    width:6.9rem;
    height:5.18rem;
    margin:.2rem auto .6rem;
  }
  .form-content{
    margin-bottom: .3rem;
    .item{
      position: relative;
      display:flex;
      height:.72rem;
      line-height:.72rem;
      flex-direction:row;
      align-items:center;
      border-bottom:1px solid $border-color;
      margin-top:.2rem;
      label{
        width:1.2rem;
        flex-shrink: 0;
        font-size:15px;
      }
      input{
        flex:1;
        height:.7rem;
        line-height:.7rem;
        border:none;
        outline:none;
      }
      .code-btn{
        width:2.3rem;
        height:30px;
        font-size: 14px;
        line-height:30px;
        text-align: right;
        cursor: pointer;
        color:$link-color;
      }
      .desc-light{color:$desc-light}
    }
    .code-box{
      flex: 1;
    }
  }
}

/* iphone5 */

@media screen and (device-width: 320px) and (-webkit-device-pixel-ratio: 2){
  .form-content{
    .code-box{
      width: 1.4rem;
      flex:none;
    }
  }

}