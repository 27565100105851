$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;


.enterprise-weChat-container{
  text-align: center;
  .content{
    position: relative;
    width:6.71rem;
    margin:0.3rem auto 0;
    overflow: hidden;
    border-radius: 16px;
  }
  .img{
    display: block;
    width:6.71rem;
    height:7.39rem;
  }
  .detail{
    background:#006fe3;
    color:#fff;
    font-size: 14px;
    padding:.1rem 0 .3rem;
  }
  .info{
    background:#fff;
    padding: .3rem 0 .4rem;
    .text{
      margin-top:.1rem;
    }
  }
 
}