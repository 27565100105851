$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.wrapper{
  padding: .2rem .2rem .6rem;
  background: #fff;
}
.wrapper .item{
  padding:0;
  margin-top:0;
}
.h1{
  font-size: 21px;
  text-align: center;
  padding-top:.2rem;
}
.h2 {
  font-size: 18px;
  padding-top:.2rem;
  padding-bottom: .2rem;
  font-weight:500;
  background: #928464;
  color: #fff;
  text-align: center;
  border-radius: 10px 10px 0 0;
}
.padding-top-10{
  padding-top: .1rem;
}
.module{
  margin-top:.6rem;
}
.module .content{
  padding:0 .2rem;
  box-sizing: border-box;
  border-left:1px solid #e5e5e5;
  border-right:1px solid #e5e5e5;
  border-bottom:1px solid #e5e5e5;
}
.module .content .detail{
  padding:.2rem 0;
}
.module .plan{
  border-top:1px solid #e5e5e5;
  padding-bottom: .2rem;
}
.module .plan:first-child{
  border-top:0;
}
.module .plan-title-item .info{
  flex:1;
}
.module .plan-title-item{
  padding-top:.2rem;
}
.module .plan-title-item .plan-title{
  background: #928464;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  width: 1.2rem;
  flex: none;
  font-size: 14px;
  height: .46rem;
  line-height: .46rem;
  margin-right:.2rem;
}
.module .item{
  font-size: 14px;
}
.module .item.border-dot{
  border-top:1px dotted #e5e5e5;
  margin-top:.2rem;
}
.module .item .item-title{
  width: 1.6rem;
  flex: none;
  font-weight: 500;
}
.module .item {
  padding-top:.2rem;
}
.module .item .info{
  flex:1;
  color: #666;
}
.module .item .info-num{
  background: #928464;
  color: #fff;
  display: inline-block;
  padding:.2rem;
}
.module .content .list .li{
  padding-top:.2rem;
}
.attention-content{
  padding-top:.2rem;
  padding-bottom: .2rem;
}
.attention-content .attention{
  margin-top:.2rem;
}
.attention-content .attention .icon-lingxing{
  font-size: 12px;
  margin-top: 3px;
  color: #E64340!important;
}
.attention-content .attention .info{
  flex:1;
  font-size: 14px;
}
