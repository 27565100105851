$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.order-wrapper{
  .box {
    margin-top: .3rem;
    padding: .3rem;
    background: $white-color;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
  .orderStatus{
    margin-top: 0;
    border-top: none;
    padding:.2rem .3rem;
    .btnCancel{
      background: #52b57f;
      color: $white-color;
      border:1px solid $white-color;
    }
    .icon-box{
      width: .8rem;
      height: .8rem;
      line-height: .8rem;
      text-align: center;
      border-radius: 100%;
      margin-right: .1rem;
      background: $white-color;
      .icon-status{
        font-size: 26px;
        margin-right: 0;
      }
      .statusDetail{
        font-size: 16px;
      }
    }
  }
  .tip-primary {
    background: #fbf4e5;
    padding: .2rem;
    font-size: 14px;
  }
  .status0, .status3, .status5, .status2{
    background: #fba30a;
    color: $white-color;
  }
  .status0 .icon-status, .status3 .icon-status, .status5 .icon-status, .status2 .icon-status{
    color: #fba30a;
  }
  .status-1, .status-3{
    background: #ea5f5d;
    color: $white-color;
  }
  .status-1 .icon-status, .status-3 .icon-status{
    color: #ea5f5d;
  }
  .status1{
    background: #52b57f;
    color: $white-color;
  }
  .status1 .icon-status{
    color: #52b57f;
  }
  .status-2, .status4{
    background: #ccc;
    color: $white-color;
  }
  .status-2 .icon-status, .status4 .icon-status{
    color: #ccc;
  }
  .msg{
    padding-top: 3px;
    font-size: 12px;
  }
  .orderTotalPrice{
    padding: .3rem;
    background: $white-color;
    margin-top: .3rem;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
  .orderTip-info{
    font-size: 14px;
  }
  .view{
    padding-top: .2rem;
    padding-bottom: .2rem;
    &:last-child{
      padding-bottom: 0;
    }
  }
  .totalNum{
    font-size: 16px;
    color: $primary-color;
    font-weight: 600;
  }
  .code{
    font-size: 14px;
    color: $primary-color;
  }
  .youhui{
    border-top: 1px solid $border-color;
    padding-top: .2rem;
    margin-top: .2rem;
  }
  .secTitle{
    padding-bottom: .3rem;
    border-bottom: 1px solid $border-color;
    font-weight: 600;
  }
  .stress{
    font-weight: bold;
  }
  .userInfo {
    margin-top: .6rem;
    background: $white-color;
    border-top:2px solid #e0dacd;
  }
  .userInfo .singel-bar{
    padding: .3rem;
    border-bottom: 1px solid $border-color;
  }
  .tag{
    width:80px;
    flex: none;
    color: $desc-v3-color;
  }
  .fs-16{
    font-size: 16px;
  }
  .padding-20{
    padding-top: .2rem;
  }
  .padding-30{
    padding-top: .3rem;
  }
  .waitting{
    color: #f39c12;
  }
  .error{
    color: #d91616;
  }
  .success{
    color: #52b57f;
  }
  .cancel{
    color: #b2b2b2;
  }
  .box:last-child{
  margin-bottom: .3rem;
  }
  .confirmNo{
    font-size: 18px;
    font-weight: 600;
  }
  .addr{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom :0;
  }
  .txt{
    flex:none;
    width: 45px;
  }
  .itemBox:last-child{
    margin-bottom: .3rem;
  }
  .itemBoxTitle{
    padding:.3rem;
    background: #f4f4f4;
    color: #333;
  }
  .itemBoxContent{
    position: relative;
    padding: .2rem 0;
  }
  .item{
    padding-top:.1rem;
    display: flex;
  }
  .item:first-child{
    padding-top: 0;
  }
  .dot{
    width: 5px;
    flex:none;
    font-weight: 600;
    color: #928464;
    margin-right: .1rem;
  }
  .youhui-tag{
    font-size: 12px;
  }
  .top{
    top: 0;
  }
  .bottom{
    bottom: -11px;
  }
  .personList{
    padding-top: 0;
    div{
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .roomType{
    padding-top: 0;
    border-bottom: 0;
  }
  .contain{
    margin-top: .1rem;
    border-bottom: 0;
    span{
      margin-left: .1rem;
      margin-right:.1rem;
      border:1px solid $border-color;
      padding:3px 5px;
      font-size:12px;
      background: #f8f8f8;
      color: #928464;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .cancelInfoShort{
    color: #52b57f !important;
  }
  .prefixDetail{
    font-size: 12px;
    padding-right: .1rem;
  }
  .addCard{
    color: #3498DB;
    display: inline-block;
    padding-bottom: 2px;
  }
  .cardTip{
    display:block;
    font-size: 13px;
    color: $desc-v3-color;
    line-height: 22px;
  }
  .box .itemTitle{
    font-size: 15px;
    border-bottom: none;
    padding-bottom: .2rem;
  }
  .itemBox{
    margin-top: .2rem;
    background: $white-color;
    padding: 0 .2rem;
  }
  .itemBox .itemTitle{
    padding: .2rem 0;
    font-size: 15px;
    font-weight: 600;
    border-bottom:1px solid $border-color;
  }
  .orderTip{
    margin: 0 auto;
    padding:.2rem;
  }
  .orderTip .icon-info{
    color: #fba30a;
    font-size: 16px;
    font-weight: bold;
  }
  .orderTip .highLight{
    color: #fba30a;
  }
  .orderTip-more{
    font-size: 12px;
    padding-bottom: .1rem;
  }
  .order-detail{
    background: #fff;
    margin-bottom: .3rem;
    .hotel-box{
      padding:.3rem;
    }
    .fapiao {
      background: #fff;
      margin-top: .2rem;
      padding-top: .2rem;
      border-top: 1px solid $border-color;
    }
  }
  .hotel-detail {
    margin-top: .2rem;
    padding: .3rem;
    background: #fff;
    font-size: 14px;
    .hotel-img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .hotel-info {
      flex: 1;
      padding-left: .2rem;
      box-sizing: border-box;
      font-size: 12px;
      .hotel-name {
        .name{
          font-weight: bold;
        font-size: 16px;
        }
      }
      .icon-dizhi{
        font-size: 12px;
      }
      .icon-tel{
        font-size: 12px;
      }
      .info {
        font-size: 12px;
        padding-top: .1rem;
      }
    }
    .room{
      margin-top: .2rem;
      border-top:1px solid $border-color;
      padding-top:.2rem;
      align-items: flex-start;
      .room-name{
        flex-shrink: 1;
        font-weight: bold;
      }
    }
    .room-attr{
      padding-top:.1rem;
      flex-wrap: wrap;
      font-size:12px;
      .attr{
        border-left: 1px solid $border-color;
        padding:0 .1rem;
        height: 12px;
        line-height: 12px;
        margin-bottom: 4px;
        &:first-child{
          border-left: none;
        }
      }
    }
    .date-info {
      padding-bottom: .2rem;
      .dateNum{
        font-weight: bold;
      }
      .line{
        width: .3rem;
        height: 1px;
        background: $border-color;
        margin: 0 10px;
      }
      .dateDay {
        font-size: 12px;
        margin-left: 3px;
      }
    }
    .plan-title {
      margin-top: .1rem;
      padding-bottom: .2rem;
      margin-bottom: .2rem;
      border-bottom: 1px solid $border-color;
      .bedCn {
        margin-left: .1rem;
      } 
      .benefitsRate {
        background: $primary-color;
        height: 16px;
        line-height: 15px;
        padding-left: 1px;
        text-align: center;
        border-radius: 3px;
        margin-right:.1rem;
        font-weight: normal;
      }
      .iconfont {
        color: #fff;
        font-size: 14px;
        margin-right: 0;
      }
      .zx-txt {
        padding-left: 0;
        font-size: 11px;
        color: #fff;
        padding-right: 2px;
      }
    }
  } 
  .personList{
    .iconfont{
      color: #928464;
    }
    .icon-boy{
      font-size: 18px;
    }
    .icon-gril{
      font-size: 18px;
    }
  }
  .link-account{
    height: 20px;
    line-height: 20px;
    color: #1f90e6;
    border: 1px solid #1f90e6;
    padding: 0 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
  }
  .replies{
    padding:.3rem;
    border:1px solid #fcd38c;
    .reply{
      font-size: 13px;
      color:#666;
      padding-top:.2rem;
      padding-bottom: .2rem;
      border-top:1px dotted $border-color;
    }
  }
  .cancelBox{
    margin-top:0;
    margin-bottom: .2rem;
    border:none;
    .icon-xinxi{
      color:#d91616;
      font-size: 20px;
    }
  }
  .info-arrow{
    flex-shrink: 0;
    font-size: 12px;
    span{
      font-size: 12px;
    }
    .iconfont.icon-xiangyoujiantou{
      color:#1f90e6;
    }
  }
  .confirmationNo{
    margin-bottom:.2rem;
    border-bottom:1px solid $border-color;
    padding-bottom: .2rem;
  }
}

/*酒店详情弹窗 开始*/
.rate-detail-wrapper {
  position: relative;
  height: 9.1rem;
  box-sizing: border-box;
  width: 7.5rem;
  padding:0 0 .6rem;
}
.rate-detail-wrapper .room-detail-content{
  font-size: 14px;
  padding-bottom: .2rem;
}
.rate-detail-wrapper .room-detail-images{
  border-radius: 5px;
  overflow: hidden;
}
.rate-detail-wrapper .item-box{
  padding:.2rem;
  border-top:1px solid $border-color;
  margin-top:.2rem;
}
.rate-detail-wrapper .item-box-title {
  padding-bottom: .1rem;
  color: #353535;
}
.rate-detail-wrapper .item-box .item-box-content {
  position: relative;
  color: #666;
}
.rate-detail-wrapper .zhuanxiang-card{
  position: relative;
  width: 7.5rem;
  height:.96rem;
  margin: 0 auto;
  z-index: 2;
  background: url('https://staticfile.badazhou.com/20201106/0d19bf3cb5e3cf0b01a71e70fac8342c.png') #f4f4f4;
  background-size: 7.5rem 1.26rem;
  background-position: 0;
  border-radius: 5px 5px 0 0;
}
.rate-detail-wrapper .zhuanxiang-card .title{
  color: #734802;
  padding-top:.1rem;
  padding-left: .2rem;
  font-size: 14px;
  font-weight: 450;
}
.rate-detail-wrapper .zhuanxiang-card .icon-huangguan{
  color:#734802;
  font-size: 21px;
}
.rate-detail-wrapper .huiyuan-card{
  position: relative;
  color: #734802;
  width: 7.5rem;
  height: .96rem;
  margin: 0 auto;
  z-index: 2;
  background: url('https://staticfile.badazhou.com/20210127/a68e101f0dcafc60217d7b56ab83017f.jpg') #f4f4f4;
  background-size: 7.5rem 1.26rem;
  background-position: 0;
  border-radius: 5px 5px 0 0;
}
.rate-detail-wrapper .huiyuan-card .title{
  padding-top:.16rem;
  padding-left: .2rem;
  font-size: 14px;
  font-weight: 450;
}
.rate-detail-wrapper .huiyuan-card .title .icon-dengji{
  color:#734802;
}
.rate-detail-wrapper .huiyuan-card .huiyuan-tip{
  padding-left: .2rem;
  margin-top:-2px;
  font-size: 11px;
}
/* 方案详情 */
.plan-title{
  margin-top:.1rem;
  padding-bottom: .2rem;
  margin-bottom: .2rem;
  border-bottom: 1px solid $border-color;
}
.plan-title .benefitsRate{
  background: $primary-color;
  height: 16px;
  line-height: 15px;
  padding-left: 1px;
  text-align: center;
  border-radius: 3px;
  margin-right: .1rem;
  font-weight: normal;
}
.plan-title .member{
  background: #eca940;
}

.plan-title .bedCn{
  margin-left: .1rem;
}
.plan-title .iconfont{
  color: #fff;
  font-size: 14px;
  margin-right: 0;
}
.plan-title .zx-txt{
  padding-left: 0;
  font-size: 11px;
  color: #fff;
  padding-right: 2px;
}
.benefit-detail-box{
  position: relative;
  background: #fff;
  padding:.2rem 0;
  font-size: 14px;
  width: 6.9rem;
  margin:0 auto;
}
.rateDescription{
  padding:.3rem;
}
.benefit-detail-box .benefit-title{
  padding-bottom: .2rem;
}
.benefit-detail-box .benefitsRate{
  background: $primary-color;
  height: 16px;
  line-height: 15px;
  padding-left: 1px;
  text-align: center;
  border: 1px solid $primary-color;
  border-radius: 3px;
  margin-right: .1rem;
  font-weight: normal;
}
.benefit-detail-box .benefits-tips{
  padding-top: .2rem;
}
.benefit-detail-box .benefits-tips .r-text{
  color: #b2b2b2!important;
  font-size: 12px;
}
.benefit-detail-box .icon-huangguan{
  color: #fff;
  font-size: 14px;
  margin-right: 0;
}
.benefit-detail-box .zx-txt{
  padding-left: 0;
  font-size: 11px;
  color: #fff;
  padding-right: 2px;
}
.benefit-detail-box .benefit-detail{
  padding: 5px;
  background: #F6F3EB;
  border: 1px solid #c7b891;
}
.benefit-detail-box .benefit-info {
  padding: 8px;
  color:#986326;
  border-radius: 2px;
  background: #fff;
  border: 1px dotted #d1c6aa;
}
.benefit-detail-box .benefit-item{
  padding-top:.2rem;
}
.benefit-detail-box .benefit-item:first-child{
  padding-top:0;
}
.benefit-detail-box .benefit-item .symbol{
  width: 6px;
  height: 6px;
  margin-top:6px;
  background-image: linear-gradient(to right, #e1b37e , #fbd8ac);
  border-radius: 1px;
  margin-right: .2rem;
  transform: rotate(45deg);
  flex:none;
}
.jifen-detail{
  margin:.3rem auto;
  padding:.3rem;
  background: #fff;
}