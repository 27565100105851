$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.certification-wrapper{
  background: #fff;
  .section {
    .tip-stress {
      background: #fbf4e5;
      padding: .2rem;
      font-size: 14px;
      color: #fc8f53;
    }
    .content{
      padding:.2rem .3rem .3rem;
      .item{
        align-items: center;
        padding-top:5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #e5e5e5;
        .label{
          width: 100px;
          flex-shrink: 0;
        }
        .input{
          flex:1;
          .ant-input{
            border:none;
          }
        }
      }
    }
  }
  .ant-form-item{
    margin-bottom: 0;
    .ant-input{
      border:1px solid $border-color;
    }
  }
  .kefu{
    padding-top:10px;
    padding-bottom:10px;
    display: block;
    text-align: center;
  }
  .otherCountry-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-top:.3rem;
    padding-bottom: .8rem;
    .otherCountry{
      .other-info{
        text-align: center;
        font-size: 16px;
      }
      .other-tip{
        padding-bottom: .4rem;
        text-align: center;
        color: #999;
        font-size: 13px;
        padding-top: .4rem;
      }
      .shenfentu{
        display: block;
        width: 90px;
        height: 61px;
        margin:0 auto;
        padding-bottom: .4rem;
      }
    }
  }
}