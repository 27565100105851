$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.hotel-booking-wrapper{
  .room-info-box{
    background: $bg-color;
    position: relative;
    z-index: 0;
    padding-top: .2rem;
    margin-bottom: .3rem;
    .tixing{
      position: relative;
      z-index: 2;
      border-radius: 5px;
      margin: 0 auto .2rem;
      background: #fff;
      padding:.1rem .2rem .2rem;
      width: 6.9rem;
      font-size: 14px;
      align-items: baseline;
      box-sizing: border-box;
      color:#fba30a;
      .icon-jifen{
        color: #fba30a;
        font-size: 15px;
        margin-top: 3px;
      }
    }
    .room-info{
      position: relative;
      width: 6.9rem;
      margin:0 auto;
      background: $white-color;
      flex-direction: column;
      font-size: 14px;
      padding:.3rem;
      box-sizing: border-box;
      border-radius: 5px;
      z-index: 2;
      .room-name{
        color: $desc-color;
        font-weight: bold;
        font-size: 16px;
      }
      .plan-title{
        padding-top:.1rem;
        color: $desc-color;
        font-size: 14px;
        span{
          padding-left: .1rem;
          &:first-child{
            padding-left: 0;
          }
        }
        .meal{
          color: #fba30a;
        }
      }
      .date-info{
        padding-top:.1rem;
        font-size: 13px;
      }
      .info{
        padding-top:.1rem;
        font-size:13px;
        color: $desc-v2-color;
      }
      span.iconfont{
        font-size: 16px;
      }
      .rate-plan-name{
        color: $desc-v3-color;
      }
      .cancel-box{
        margin-top:.2rem;
        padding-top:.2rem;
        border-top:1px solid $border-color;
        .cancel-info{
          flex:1
        }
        .link-text{
          flex:none;
          width: .8rem;
          text-align: right;
        }
      }
      .cancel-en{
        align-items: flex-end;
        justify-content: space-between;
      }
      .rate-fail{
        padding:.1rem 0 0;
      }
    }
  }
  .room-benefits{
    .zhuanxiang-card{
      position: relative;
      width: 6.9rem;
      height: 1.16rem;
      margin: 0 auto;
      z-index: 2;
      background: url('https://staticfile.badazhou.com/20201106/0d19bf3cb5e3cf0b01a71e70fac8342c.png');
      background-size: 6.9rem 1.16rem;
      .icon-huangguan{
        color:#734802;
        font-size: 21px;
      }
      .title{
        color:#734802;
        padding-top: 4px;
        padding-left: .2rem;
        font-size: 14px;
        font-weight: 450;
      }
    }
    .huiyuan-card{
      position: relative;
      color: #734802;
      width: 6.9rem;
      height: .96rem;
      margin: 0 auto;
      z-index: 2;
      background: url('https://staticfile.badazhou.com/20210127/a68e101f0dcafc60217d7b56ab83017f.jpg') $bg-color;
      background-size: 7.5rem 1.26rem;
      background-position: 0;
      border-radius: 5px 5px 0 0;
      .title{
        padding-top:2px;
        height: 26px;
        padding-left: .2rem;
        font-size: 14px;
        font-weight: 450;
        .icon-dengji{
          color:#734802;
        }
      }
      .huiyuan-tip{
        padding-left: .2rem;
        margin-top:-2px;
        font-size: 11px;
      }
    }
    .room-info{
      border-radius: 0 0 5px 5px;
    }
  }
  .room-info-bg{
    position: absolute;
    background: #e6dcc3;
    width: 100%;
    height:3rem;
    left: 0;
    top:0;
    z-index: 1;
  }
  .room-info-bg{
    height: 3.6rem;
  }
  .room-info{
    border-radius: 0 0 5px 5px;
  }
  .form-wrapper{
    background: $bg-color;
    position: relative;
    padding:0;
    padding-bottom: 85px;
    .section{
      padding:.3rem .3rem 0;
      margin-top: .3rem;
      background: $white-color;
      .section-title{
        font-size: 16px;
        font-weight: bold;
      }
      .value{
        font-size: 16px;
      }
    }
    .member-rate-item{
      flex-direction: column;
      width: 6.9rem;
      border-top:1px solid #e5e5e5;
      .hotel-member-rate {
        font-size: 13px;
        background: #fbf4e5;
        padding: 5px;
        margin-top: .2rem;
        border-radius: 2px;
      }
      .member-rate{
        padding:.1rem 0 .3rem;
        border:0;
      }
    }
    .icon-shuoming{
      width: 14px;
      height: 14px;
      line-height: 12px;
      text-align: center;
      border-radius: 100%;
      border: 1px solid #1f90e6;
      margin-left: 5px;
      color: #1f90e6;
      font-size: 12px;
      font-weight: normal;
    }
    .rp{
      padding-bottom: 15px;
    }
    .card-section{
      .div-info{
        padding-top:.1rem;
        padding-bottom: .3rem;
      }
      .card-type{
        padding-bottom: 10px;
      }
      .card-style {
        display: inline-block;
        width: 1.2rem;
        height: .74rem;
        background: url('https://staticfile.badazhou.com/20201106/336c355cc195edf31fb5d263ef4768d7.jpg') no-repeat;
        background-size: 100%;
        margin-left: .3rem;
        border: 1px solid $white-color;
        &:first-child{
          margin-left: 0;
        }
      }
      .active{
        border-color:#fc8f53;
      }
      .cardVI{
        background-position: 0 0;
      }
      .cardMC{
        background-position: 0 -1.54rem;
      }
      .cardAX{
        background-position: 0 -.74rem;
      }
      .exp-item{
        padding:20px 0;
      }     
    }
    .rooms-tip{
      color: $primary-v2-color;
      font-size: 12px;
      padding:.1rem 0;
      line-height: 16px;
    }
    .bottom-box{
      position: fixed;
      width: 100%;
      height: 60px;
      left: 50%;
      transform: translateX(-50%);
      bottom:0;
      padding:10px 15px;
      border-top: 1px solid $border-color;
      background: $white-color;
      z-index: 2000;
      .price-list{
        flex:1;
        width: 4rem;
        .totalPrice{
          font-size: 18px;
          color: $primary-color;
          align-items: baseline;
        }
        .mingxi{
          margin-left: 5px;
        }
        .icon-xiangshangjiantou {
          font-size: 12px;
          margin-left: 5px;
          font-weight: 600;
          margin-right: 0;
        }
      }
    }
  }
  .agreeTip{
    font-size: 12px;
    color: $desc-v2-color;
    padding: .3rem;
  }
}

.pricel-list-wrapper{
  position: relative;
  height: 8.8rem;
  padding-bottom: 65px;
  box-sizing: border-box;
  width: 7.5rem;
  padding:0;
  font-size: 14px;
  .item-box{
    background: $white-color;
    font-size: 14px;
    padding-top:.3rem;
    padding-bottom:.8rem;
    border-top:1rpx solid $border-color;
    width: 6.9rem;
    margin:0 auto;
    .item-box-title{
      padding-bottom: .1rem;
      color: $desc-color;
    }
  }
  .margin-top-30{
    margin-top: .3rem;
  }
  .priceContent{
    padding:.3rem;
    .priceTitle{
      padding-bottom: .3rem;
      font-weight: 600;
      border-bottom: 1px solid $border-color;
    }
    .price-info{
      padding:.3rem 0;
    }
    .price-box{
      padding:.2rem;
      background: #f8f8f8;
      border-radius: 5px;
      .price-item{
        padding:.2rem 0;
        border-top:1px dashed $border-color;
        &:first-child{
          padding-top:0;
          border-top:0;
        }
      }
    }
    .currency{
      font-weight: normal;
    }
    .tagInfo{
      color: $primary-color;
    }
  }
}

.card-date-wrapper{
  padding:.3rem;
  padding-bottom: 75px;
  .date-box {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    .number {
      border-radius: 3px;
      background: #eee;
      color: $desc-color;
      text-align: center;
      padding: .2rem 0;
      margin-top: .2rem;
      width: 1.57rem;
    }
    .active{
      color:#fc8f53;
      background:#f9e5db;
    }
    .margin-right-none {
      margin-right: 0 !important;
    }
  }
}