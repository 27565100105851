$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.xhs-container{
  width: 7.5rem;
  position: relative;
  padding:0;
  margin:0 auto;
  z-index:1;
  overflow:hidden;
}
.popup{
  overflow-y: hidden;
  padding-bottom: .8rem;
}
.stress{
  font-weight:600;
  color:#fb5f0a;
}
.wx-tip{
  text-align:center;
  font-size:12px;
  margin-bottom:20px;
}
.container {
  width: 7.5rem;

  img{
    display: block;
    width: 100%;
    position: relative;
    margin: 0;
  }

  .img1{
    width: 100%;
    height: auto;
  }

  .img2{
    width: 100%;
    height: auto;
  }

  .img3{
    width: 100%;
    height: auto;
  }

  .img4{
    width: 100%;
    height: auto;
  }

  .img5 {
    width: 100%;
    height: auto;
  }

  .img6 {
    width: 100%;
    height: auto;
  }
}

.lingqu-box{
    padding: 10px 0;
    position:fixed;
    z-index:50;
    width:7.5rem;
    // left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom:0;
    background:#fff;
    box-shadow: 0 -3px 3px #ccc;
}

.get-box{
  width: 6.5rem;
  color: #fff;
  text-align: center;
  font-size: 19px;
  border-radius: 32px;
  padding: 7px 10px;
}

.get-box-status-0 {
  background-color: #fb5f0a;
}

.get-box-status-1 {
  background-color: #65ce91;
}



