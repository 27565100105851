$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;


.area-list-wrapper {
  padding:$space-number;
  box-sizing: border-box;
  background: $white-color;
  .search{
    margin-bottom: .3rem;
  }
  .ant-tabs-nav-list{
    width:100%;
    overflow: hidden;
    .ant-tabs-tab{
      display:flex;
      flex-direction: row;
      justify-content:center;
      width:1.72rem;
      margin:0;
    }
  }
  .city{
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background:#f4f4f4;
    padding:.16rem 0;
  }
}

