$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.club8-user-agreement-wrapper {
  background-color: #fff;
  .h1{
    text-align: center;
    font-weight: bold;
    color: #353535;
    padding: 15px 0;
    font-size: 18px;
  }
  .h2-uni{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding: 15px 0;
  }
  .p{
    padding:15px 15px 0;
    background-color: #fff;
  }
  .item{
    margin-top: 15px;
    padding: 0 15px;
  }
  .title {
    font-weight: bold;
    color: #353535;
    padding: 15px 0;
    font-size: 17px;
  }
  .sub-title{
    font-weight: bold;
    color: #353535;
    padding: 15px 0;
    font-size: 14px;
  }
  .circle {
    margin-right: 5px;
  }
  .text {
    color: #666;
    line-height: 22px;
    padding-bottom: 15px;
  }
  .text-info{
    color: #666;
    line-height: 22px;
    padding-top: 5px;
    font-size: 14px;
  }
  .brands .text-info{
    font-size: 14px;
  }
  .pdb-30 {
    padding-bottom: 15px;
  }
  .mb-20 {
    margin-bottom: 10px;
  }
  .text-num{
    padding-right: 5px;
  }
  .pl-12 {
    padding-left: 12px;
  }
  .c666{
    color: #666;
  }
  .preferential{
    display: inline-block;
    color:#fba30a;
    font-size:12px;
    margin: 3px 3px 0;
    height: 18px;
    line-height: 18px;
    border: 1px solid #fba30a;
    font-weight: 500;
    padding-right: 2px;
  }
  .zhuanxiangImg{
    width: 16px;
    height: 18px;
    padding-left:1px;
    padding-right: 1px;
    background:#fba30a;
    margin-right: 2px;
    float: left;
  }
  .zhuanxiangImg text{
    color: #fff;
    margin-right: 0;
    font-size: 14px;
  }
  .senven .text{
    padding-bottom:0;
  }
  .list{
    padding-top:5px;
    color:#666;
    font-size: 14px;
  }
  .list .li{
    padding-bottom: 5px;
  }
  .list .dot{
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #333;
    margin-right: 10px;
    margin-top: 8px;
    flex:none;
  }
  .list .li .text{
    flex: 1;
    padding-bottom: 0;
  }
  .huangguan-box{
    background: #fb5f0a;
    height: 16px;
    line-height: 15px;
    padding-left: 1px;
    text-align: center;
    border: 1px solid #fb5f0a;
    border-radius: 3px;
    display: inline-block;
    margin-right: 5px;
    margin-left: 10px;
  }
  .huangguan-box .icon-huangguan{
    color: #fff;
    font-size: 14px;
    margin-right: 0;
  }
  .list .dt{
    font-weight: bold;
    color: #333;
  }
  .list .xiushi-line{
    width: 3px;
    height: 15px;
    background: #9e8250;
    border-radius: 8px;
    margin-right: 10px;
  }
  .list .dd{
    padding-top: 10px;
  }
  .bold{
    color: #333!important;
  }  
}


.cc{
  font-size: 12px;
}