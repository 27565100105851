$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.tab-bar{
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background:#fff;
  height:50px;
  display:flex;
  flex-direction: row;
  box-shadow: 0 0 3px #ddd;
  .tab-view{
    flex:1;
    padding-top:3px;
  }
  .tab{
    height: 44px;
    color:#999;
    font-size:14px;
    display: flex;
    flex-direction:column;
    text-align: center;
    align-items:center;
    justify-content:center;
    .icon{
      width:21px;
      height:21px;
    }
  }
  .selected{
    color: $primary-color;
  }
}