$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.page-content{
  background: #a77f5a;
  position: relative;
  .btn-box{
    padding-top:.3rem;
    background: url('https://staticfile.badazhou.com/20230201/7e38a45516f1335c9028c91dd247c331.jpeg') no-repeat;
    width:7.5rem;
    height:2.67rem;
    background-size: 7.5rem 2.67rem;
    box-sizing: border-box;
  }
  .btn-box .btn{
    display:block;
    width:4.34rem; 
    height:.88rem;
    margin: 0 auto;
  }
  .btn-box .input{
    margin: 0 auto .2rem;
    display:block;
    width:6rem;
    height: .8rem;
    line-height: .8rem;
    border:1px solid #333;
    border-radius: 8px;
  }
  .img{
    display: block;
    width: 7.5rem;
    position: relative;
    z-index: 1;
    margin:0 auto;
  }
  .img1{
    height: 10.15rem;
  }
  .img2{
    height: 1.6rem;
  }
  .img3{
    height: 7.46rem;
  }
  .img4{
    height: 5.58rem;
  }
}