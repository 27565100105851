$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.hotel-wrapper{
  padding-bottom: 65px;
  .xcx{
    display: flex;
    width: 7.5rem;
    height: .8rem;
    margin:0;
    background:#333333;
    color:#fff;
   justify-content:space-between;
   padding-right:.3rem;
    align-items:center;
    box-sizing: border-box;
    .img{
      width:5.14rem; 
      height:.8rem;
    }
    .btn{
      background:#fb5f0a;
      padding:.06rem .3rem;
      border-radius: 30px;
      animation: scaleChange 1s infinite alternate;
    }
  }
  .banner{
    width:7.5rem;
    height:2.5rem;
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .search-box{
    position: relative;
    background:$white-color;
    padding:$space-number;
    .search-item{
      color:$desc-color;
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top:$space-number;
      padding-bottom:$space-number;
      border-bottom:1px solid $border-color;
      &:first-child {
        padding-top:0;
      }
      .date-item{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        .dateNum {
          font-size: 16px;
          font-weight: bold;
        }
        .dateDay {
          font-size: 12px;
          margin-left: .05rem;
        }
        .days-box {
          position: relative;
          font-size: 12px;
          width: 1.6rem;
          text-align: center;
          margin: 0 .4rem;
          .line {
            position: absolute;
            left: 0;
            top: .24rem;
            width: 1.6rem;
            background: $border-color;
            height: 1px;
            z-index: 0;
          }
          .days {
            position: relative;
            z-index: 2;
            padding: 0 .1rem;
            background: $white-color;
            font-weight: normal;
            display:inline-block;
            margin-top:5px;
          }
        }
      }
    }
  }
  .brands{
    padding:$space-number;
    margin-top:$space-number;
    background:$white-color;
    .title{
      font-size:18px;
      text-align:center;
    }
    .desc{
      text-align: center;
      padding-top: $space-number;
      padding-bottom: $space-number;
      color: $desc-v3-color;
    }
    .swiper{
      width:6.52rem;
      height:2.96rem;
      margin:0 auto;
      .swiper-slide{
        width:6.52rem;
        height:2.96rem;
        .img{
          display:block;
          width:6.52rem;
          height:2.96rem;
        }
      }
    }
  }
  .jd-link{
    margin-top:.3rem;
    width: 7.5rem;
    height: 5.26rem;
  }
}

@keyframes scaleChange {
  from { transform: scale(1)}
  to { transform: scale(.9)}
}

