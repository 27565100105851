$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.lt-silver-card-container{
  background: #f4edda;
  position: relative;
  .img{
    display: block;
    width: 7.5rem;
    position: relative;
    z-index: 1;
    margin:0 auto;
  }
  .img1{
    height: 8.07rem;
  }
  .img2{
    height: 6.59rem;
  }
  .img3{
    height: 1.42rem;
  }
  .img4{
    height: 9.42rem;
  }
  .img5{
    height: 7.56rem;
  }
  .img6{
    height: 8.92rem;
  }
} 