$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.wrapper {
  .pdb-30 {
    padding-bottom: .3rem;
  }
  .mb-20 {
    margin-bottom: .2rem;
  }
  .item{
    margin-top: .3rem;
    padding: 0 .3rem;
    background: $white-color;
    &:first-child{
      margin-top:0;
    }
    &:last-child{
      padding-bottom: .3rem;
    }
    .title {
      font-weight: bold;
      color: $desc-color;
      padding: .3rem 0;
      font-size: 17px;
    }
    .sub-title{
      font-weight: bold;
      color: $desc-color;
      padding: .3rem 0;
      font-size: 14px;
    }
    .circle {
      margin-right: 5px;
    }
    .text {
      color: #666;
      line-height: 22px;
      padding-bottom: .6rem;
    }
    .text-num{
      padding-right:.1rem;
    }
    .pl-12 {
      padding-left: 12px;
    }
    .c666{
      color: #666;
    }
    .preferential{
      display: inline-block;
      color:#fba30a;
      font-size:12px;
      margin:3px 3px 0;
      height: 18px;
      line-height: 18px;
      border: 1px solid #fba30a;
      font-weight: 500;
      padding-right: 2px;
    }
    .zhuanxiangImg{
      width: 16px;
      height: 18px;
      padding-left:1px;
      padding-right: 1px;
      background:#fba30a;
      margin-right: 2px;
      float: left;
    }
    .zhuanxiangImg text{
      color: $white-color;
      margin-right: 0;
      font-size: 14px;
    }
    .senven .text{
      padding-bottom:0;
    }
  }
}




